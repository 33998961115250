import React from "react"
import ProductsBanner from "../../components/Products/ProductsBanner"
import Layout from "../../components/layout"
import CardProducts from "../../components/Products/CardProducts"

const ourPoducts = () => {
  return (
    <Layout>
      <>
        <ProductsBanner />
        <CardProducts />
      </>
    </Layout>
  )
}

export default ourPoducts
