import { FaRegPlayCircle } from "react-icons/fa"
import { GoArrowUpRight } from "react-icons/go"
import { Link } from "theme-ui"
import poojaStores from "../../../static/Client/poojaStores.png"
import yogiAhar from "../../../static/Client/yogiAhar.jpg"
import kiku from "../../../static/Client/kiku.jpg"
import amnet from "../../../static/Client/amnet.png"

import { useState } from "react"
import PropTypes from "prop-types"
import React from "react"

import Button from "../common/Button"
const Products = ({ name, src, alt, link, description, techStack, url }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div className="flex flex-col md:flex-row items-center glassmorphism border-white/50 border p-4 gap-4">
      {/* Text Content Section */}
      <div className="md:w-1/2">
        <div className="p-2">
          <Link
            to={url}
            target="_blank"
            className="flex items-center gap-2 group w-fit"
          >
            <h4 className="relative font-bold text-xl inline-block">
              {name}
              <span className="absolute h-[1px] w-full bg-black bottom-0 left-0 mt-1 transition-transform transform origin-left scale-x-0 group-hover:scale-x-100"></span>
            </h4>
          </Link>
          <p className="text-white text-sm leading-relaxed">{description}</p>
          <Link to="#">
            <Button name="Browse" />
          </Link>
        </div>
      </div>

      {/* Image Section */}
      <div
        className="overflow-hidden relative md:w-1/2 rounded-lg shadow-lg"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-105"
          src={src}
          alt={alt}
        />
      </div>
    </div>
  )
}

Products.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  techStack: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string.isRequired,
}

const CardProducts = () => {
  const data = {
    features: [
      {
        name: "Sri Pooja Stores",
        src: poojaStores,
        alt: "Pooja Stores",
        url: "https://demo-pooja-stores.netlify.app/",
        description:
          "Pooja Stores specializes in shipping pooja kits and religious products globally, ensuring that customers worldwide receive high-quality, authentic items for their spiritual practices. With reliable international delivery, they cater to diverse cultural and devotional needs.",
      },
      {
        name: "Yogi Ahar",
        src: yogiAhar,
        alt: "Yogi Ahar",
        description:
          "The Yogi Ahar Restaurant food application streamlines operations by allowing the manager to oversee activities, kitchen employees to receive and prepare orders, and waiters to efficiently manage table service.",
      },
      {
        name: "Kwiku",
        src: kiku,
        alt: "Kwiku",
        description:
          "Kwiku is a dynamic ride-hailing app that connects users with Bike and Auto Services for fast, affordable, and convenient transportation. Offering a seamless booking experience, it is ideal for navigating busy urban areas with ease.",
      },
      {
        name: "Amnet",
        src: amnet,
        alt: "Amnet Image",
        // link: "https://amnet.com/",
        url: "https://amnet.com/",
        description:
          "Amnet CMS offers comprehensive content management services, providing solutions for businesses seeking efficient digital content management. With a focus on user-friendly interfaces,robust functionalities, Amnet CMS contains content creation, editing, and publishing processes.",
      },
    ],
  }

  return (
    <div className="grid xl:grid-cols-2  grid-cols-1 justify-center 2xl:px-[160px] sm:px-[50px] px-[10px]  gap-16 p-4 md:mt-10 mt-4">
      {data.features.map((feature, index) => (
        <Products key={index} {...feature} />
      ))}
    </div>
  )
}

export default CardProducts
